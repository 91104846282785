// Migrated
<template lang="pug">
fa(v-if="blockImg || !picture" :icon="icons.faUser")
img(v-else, :src="picture" :alt="alt" width="32" height="32")
</template>

<script>
import { faUser } from '@fortawesome/pro-duotone-svg-icons'

export default defineNuxtComponent({
  props: {
    avatar: {
      type: String,
      default: null,
    },

    alt: {
      type: String,
      default: null,
    },

    blockImg: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      picture: null,
      icons: {
        faUser,
      },
    }
  },

  watch: {
    avatar () {
      this.picture = this.avatar
    },
  },

  mounted () {
    if (this.avatar) {
      preloadImage(this.avatar)
        .then(() => (this.picture = this.avatar))
        .catch(() => {})
    }
  },
})
</script>
