// Migrated
<template lang="pug">
.d-contents(v-if="canLocaleLogin")
  LoginHeaderMenu(
    v-bind="loginHeaderProps",
    :username="user?.name",
    :logged-in="isLoggedIn",
    @login="login"
  )
    template(#avatar)
      LoginHeaderAvatar(:avatar="avatar" :alt="user?.name" :block-img="!isLoggedIn")
</template>

<script>
import { mapState } from 'pinia'
import { faUser } from '@fortawesome/pro-duotone-svg-icons'

import events from '@layers/web/constants/events.js'

export default defineNuxtComponent({
  props: {
    showSearchform: {
      type: Boolean,
      default: false,
    },

    isMobile: {
      type: Boolean,
      default: false,
    },

    drop: {
      type: String,
      default: 'left',
    },
  },

  emits: [events.loginWithAction],

  data () {
    return {
      icons: {
        faUser,
      },
    }
  },

  computed: {
    ...mapState(useLocaleStore, ['canLocaleLogin']),
    ...mapState(useUserStore, ['user', 'avatar', 'isLoggedIn']),

    loginHeaderProps () {
      return this.$props
    },
  },

  methods: {
    login (payload = null) {
      this.timer = window.setTimeout(() => {
        this.$event.$emit(events.loginWithAction, payload)
      }, 250)
    },
  },
})
</script>
